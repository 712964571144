<template>
  <default-layout>
    <v-container id="refund-manager-add" fluid tag="section">
      <refund-manager-form />
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import { mapState } from 'vuex';
const RefundManagerForm = () => import('@/components/refund-management/manager/refund/form.vue');

export default {
  name: 'refund-manager-add',
  components: {
    DefaultLayout,
    RefundManagerForm,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({}),
  },
  async created() {
    await this.$store.dispatch('refundManager/form/restoreInitialState');
    this.$store.commit('refundManager/form/SET_FORM_TYPE', 'ADD');
    await this.$store.dispatch('refundManager/form/fetchBaseData');
  },
};
</script>
